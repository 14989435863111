<template>
    <div class="appUserAddEdit">
        <!-- Drawer抽屉 -->
        <Drawer :title="title" v-model="visible" width="720" on-close="handleClose()" :mask-closable="false">
            <Form ref="form" :model="form" :label-width="120" :rules="formValidate" label-position="left">
                <FormItem label="手机" prop="telno">
                    <Input v-model="form.telno" clearable style="width:100%"/>
                </FormItem>
                <FormItem label="邮箱" prop="email">
                    <Input v-model="form.email" clearable style="width:100%"/>
                </FormItem>
                <FormItem label="密码" prop="pwd">
                    <Input v-model="form.pwd" clearable style="width:100%"/>
                </FormItem>
                <FormItem label="过期期限" prop="buyDue">
                    <Date-picker type="datetime" :value="form.buyDue" clearable style="width:100%" format="yyyy.MM.dd" @on-change="form.buyDue = $event"></Date-picker>
                </FormItem>
                <FormItem label="插入日期" prop="instime">
                    <Date-picker type="datetime" :value="form.instime" readonly style="width:100%" format="yyyy.MM.dd" @on-change="form.instime = $event"></Date-picker>
                </FormItem>
                <FormItem label="更新日期" prop="updtime">
                    <Date-picker type="datetime" :value="form.updtime" readonly style="width:100%" format="yyyy.MM.dd" @on-change="form.updtime = $event"></Date-picker>
                </FormItem>
                <FormItem label="密码" prop="pwd2">
					<span >{{formExt.pwd2}}</span>
                </FormItem>
                <Form-item class="br">
                    <Button @click="handleSubmit" :loading="submitLoading">保存</Button>
                    <Button @click="handleData" :loading="submitLoading">查看</Button>
                    <Button @click="handleClose">关闭</Button>
                </Form-item>
            </Form>
        </Drawer>
    </div>
</template>
<script>
	// 根据你的实际请求api.js位置路径修改
	import {
		addAppUser,
		editAppUser,
		queryData
	} from "@/api/busi/data-manage/appUser";
	export default {
		name: "appUserAddEdit",
		components: {},
		props: {
			data: Object,
			addEditFlag: String,
			value: {
				type: Boolean,
				default: false,
			},
		},
		data() {
			return {
				submitLoading: false, // 表单提交状态
				// TODO
				title: "App用户编辑",
				visible: this.value,
                form: { // 添加或编辑表单对象初始化数据
                    telno: "",
                    email: "",
                    pwd: "",
                    buyDue: "",
                    instime: "",
                    updtime: "",
                },
				formExt: {
					pwd2: "",
				},
                // 表单验证规则
                formValidate: {
                }
            };
        },
		methods: {
			init() {
				// 编辑情况下
				if (this.addEditFlag == "2") {
					this.form = this.data;
				}else{
					delete this.form.id;
				}
			},
			setCurrentValue(value) {
				if (value === this.visible) {
					this.$refs.form.resetFields();
				 return;
				}
				this.init();
				this.visible = value;
			},
			handleReset() {
				this.$refs.form.resetFields();
			},
			handleSubmit() {
				this.$refs.form.validate(valid => {
					if (valid) {
						if (this.addEditFlag == "1") {
							addAppUser(this.form).then(res => {
								this.submitLoading = false;
								if (res.success) {
									this.$Message.success("操作成功");
									this.$emit("on-submit", true);
									this.visible = false;
								}
							});
						}else{
							editAppUser(this.form).then(res => {
								this.submitLoading = false;
								if (res.success) {
									this.$Message.success("操作成功");
									this.$emit("on-submit", true);
									this.visible = false;
								}
							});
						}
					}
				});
			},
			handleData() {
				queryData(this.form).then(res => {
					this.submitLoading = false;
					if (res.success) {
						this.$Message.success("操作成功");
						this.formExt.pwd2 = res.result;
					}
				});
			},
			handleClose(){
				this.visible = false;
			}

		},
		watch: {
			value(val) {
				this.setCurrentValue(val);
			},
			visible(value) {
				this.$emit("input", value);
			},
		},
		mounted() {
			this.init();
		}
	};
</script>

<style lang="less">
// 建议引入通用样式 具体路径自行修改
//@import "../../../styles/table-common.less";
</style>