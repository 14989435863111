// 统一请求路径前缀在libs/axios.js中修改
import { getRequest, postRequest, putRequest, postBodyRequest, getNoAuthRequest, postNoAuthRequest } from '@/libs/axios';

// 分页获取数据
export const getAppUserList = (params) => {
    return postRequest('/busi/appUser/getByCondition', params)
}
// 添加
export const addAppUser = (params) => {
    return postRequest('/busi/appUser/insert', params)
}
// 编辑
export const editAppUser = (params) => {
    return postRequest('/busi/appUser/update', params)
}
// 删除
export const deleteAppUser = (params) => {
    return postRequest('/busi/appUser/delByIds', params)
}
// 查看
export const queryData = (params) => {
    return postRequest('/busi/appUser/queryData', params)
}
